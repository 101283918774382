import { cn } from '@paladise/ui/lib/utils'
import { useTranslations } from 'next-intl'
import { toast, ToastClassnames } from 'sonner'

interface ToastOptions {
  description?: string
  icon?: React.ReactNode
  actionLabel?: string
  actionOnClick?: () => void
  classNames?: ToastClassnames
}

const useToast = () => {
  const t = useTranslations()

  const showErrorToast = ({
    description,
    actionLabel,
    actionOnClick,
    classNames,
    icon,
  }: ToastOptions) => {
    return toast('Error', {
      description: description || 'Something went wrong. Please try again!',
      icon: icon || <span className="i-ps-warning_rou_s text-alert size-5" />,
      unstyled: true,
      classNames: {
        toast: cn(
          'items-start left-[25%] bg-message-error shadow-floating',
          classNames?.toast,
        ),
        icon: cn('items-start', classNames?.icon),
        actionButton: cn('min768:text-nowrap', classNames?.actionButton),
        description: cn('min768:text-nowrap', classNames?.description),
      },
      action:
        actionLabel && actionOnClick
          ? {
              label: actionLabel,
              onClick: () => actionOnClick(),
            }
          : undefined,
      onAutoClose: () => {},
    })
  }

  const showSuccessToast = ({
    description,
    actionLabel,
    actionOnClick,
    classNames,
    icon,
  }: ToastOptions) => {
    return toast.success('Success', {
      description,
      icon: icon || (
        <span className="i-ps-check_rou_s text-primary-p1 size-5" />
      ),
      unstyled: true,
      classNames: {
        toast: cn(
          'items-start left-[25%] bg-message-success  shadow-floating',
          classNames?.toast,
        ),
        icon: cn('items-start', classNames?.icon),
        actionButton: cn(
          'text-primary-p1 text-nowrap',
          classNames?.actionButton,
        ),
        description: classNames?.description,
      },
      action: actionOnClick
        ? {
            label: actionLabel || t('ok'),
            onClick: () => actionOnClick(),
          }
        : undefined,
      onAutoClose: () => {},
    })
  }

  return {
    showErrorToast,
    showSuccessToast,
  }
}

export default useToast
