'use client'
import React, { useState, useTransition } from 'react'
import ThirdPartyAuth from './ThirdPartyAuth'
import { Button } from '@paladise/ui/components/ui/button'
import LoginForm from './LoginForm'
import { TextField } from '@paladise/ui/components/TextField'
import { useTranslations } from 'next-intl'
import { useForm } from '@conform-to/react'
import { parseWithZod } from '@conform-to/zod'
import { z } from 'zod'
import { AccountCheckSchema } from '../schema'
import { checkEmailAvailability } from 'features/account/utils/checkEmailAvailability'
import DividerWithText from 'components/DividerWithText'

interface Prop {
  onSignUp: () => void
}

function Login({ onSignUp }: Prop) {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [isLoading, startTransition] = useTransition()

  const t = useTranslations()
  const [form, fields] = useForm({
    id: 'email-form',
    shouldValidate: 'onInput',
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: AccountCheckSchema })
    },
  })

  const EmailError =
    fields.email.errors?.[0] && t(fields.email.errors?.[0] as 'required')

  const handleNext = async (formData: FormData) => {
    const submission = parseWithZod(formData, {
      schema: AccountCheckSchema,
    })

    const { email } = (
      submission as { value: z.infer<typeof AccountCheckSchema> }
    ).value
    // TODO: 等api調整 目前沒 auth token 打不通
    // const available = await checkEmailAvailability(email)
    // if (available) {
    //   setError('Account does not exist.')
    //   return
    // }
    setEmail(email)
  }

  if (email) return <LoginForm email={email} />

  return (
    <div className="min768:w-[300px] mx-auto h-full w-full">
      <p className="text-label-l1 text-title mb-2">Log in to PalUp</p>
      <p className="text-label-l2 text-body mb-8">
        Log in to get free credits to chat with diverse AI pals and customize
        your own.
      </p>
      <ThirdPartyAuth />
      <DividerWithText className="mb-8 mt-6" text="Or" />

      <form
        className="text-callout text-label-l1 space-y-4"
        id={form.id}
        onSubmit={form.onSubmit}
        action={data => {
          startTransition(async () => {
            handleNext(data)
          })
        }}
        noValidate
      >
        <TextField
          label={'Email'}
          name={fields.email.name}
          isInvalid={!!fields.email.errors || !!error}
          errorMessage={EmailError || error}
          placeholder={'Email'}
        />
        <Button
          type="submit"
          className="bg-background-2nd border-label-l3 text-label-l1 text-callout mb-4 h-12 w-full border"
          loading={isLoading}
          disabled={isLoading}
        >
          Next
        </Button>
      </form>

      <p className="text-label-l1 text-caption">
        Don’t have an account?
        <Button
          variant="text"
          className="text-subtitle text-primary-p1 ml-1 p-0"
          onClick={onSignUp}
        >
          Sign up
        </Button>
      </p>
    </div>
  )
}

export default Login
