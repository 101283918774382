'use client'

import type { DATA_ATTRIBUTES } from '@paladise/tracker/types'
import { chain } from '@paladise/utils/chain'
import React from 'react'
import { cn } from '../lib/utils'
import { Input } from './ui/input'
import { Description, FieldError, Label } from './ui/label'
import { Textarea } from './ui/textarea'

export interface TextFieldProps
  extends Omit<React.ComponentProps<'input'>, 'onChange'> {
  label?: string | JSX.Element
  description?: string
  errorMessage?: string
  containerProps?: React.ComponentProps<'div'>
  dataAttributes?: DATA_ATTRIBUTES
  isInvalid?: boolean
  isViolated?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export function TextField({
  label,
  required,
  description,
  errorMessage,
  containerProps = {},
  dataAttributes,
  isViolated,
  onChange,
  ...props
}: TextFieldProps) {
  return (
    <div
      {...containerProps}
      className={cn('flex flex-col space-y-[4px]', containerProps.className)}
    >
      {label && (
        <Label required={required} isViolated={isViolated}>
          {label}
        </Label>
      )}

      {description && <Description>{description}</Description>}
      <Input
        required={required}
        data-tracker-click={dataAttributes?.['data-tracker-click']}
        data-tracker-typing={dataAttributes?.['data-tracker-typing']}
        onChange={onChange}
        {...props}
      />
      {errorMessage && (
        // add div container to make sure data-attribute can be catch
        <div data-tracker-show={dataAttributes?.['data-tracker-show']}>
          <FieldError>{errorMessage}</FieldError>
        </div>
      )}
    </div>
  )
}

export interface TextAreaProps
  extends Omit<React.ComponentProps<'textarea'>, 'onChange'> {
  defaultValue?: string
  value?: string
  label?: string
  required?: boolean
  labelExtra?: React.ReactNode
  description?: string
  showLength?: boolean
  errorMessage?: string
  containerProps?: React.ComponentProps<'div'>
  dataAttributes?: DATA_ATTRIBUTES
  isInvalid?: boolean
  isViolated?: boolean
  onChange?: (value: string) => void
}

export function TextAreaField({
  label,
  required,
  labelExtra,
  description,
  errorMessage,
  showLength,
  rows,
  containerProps = {},
  onChange,
  dataAttributes,
  isViolated,
  ...props
}: TextAreaProps) {
  const [length, dispatch] = React.useReducer(
    (_: number, value: string) => value.length,
    props.defaultValue?.length ?? props.value?.length ?? 0,
  )

  return (
    <div
      {...containerProps}
      className={cn('flex flex-col space-y-[4px]', containerProps.className)}
    >
      {label && (
        <Label required={required} isViolated={isViolated}>
          {label}
        </Label>
      )}
      {labelExtra && (
        <div className="absolute right-0 top-0 !m-0">{labelExtra}</div>
      )}
      {description && <Description>{description}</Description>}
      <Textarea
        data-tracker-click={dataAttributes?.['data-tracker-click']}
        data-tracker-typing={dataAttributes?.['data-tracker-typing']}
        rows={rows}
        onChange={event => chain(dispatch, onChange)(event.target.value)}
        {...props}
      />
      {showLength && (
        <p className="length text-label-l2 text-right tabular-nums">
          {length}/{props.maxLength}
        </p>
      )}
      {errorMessage && <FieldError>{errorMessage}</FieldError>}
    </div>
  )
}
