'use client'

import { cn } from '@paladise/ui/lib/utils'
import * as LabelPrimitive from '@radix-ui/react-label'
import * as React from 'react'

interface LabelProps
  extends React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> {
  required?: boolean
  isViolated?: boolean
}

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  LabelProps
>(({ className, required, isViolated, children, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={cn('font-semibold peer-disabled:cursor-not-allowed', className)}
    {...props}
  >
    {children}
    {required && <span className="text-third-p1 pl-0.5">*</span>}
    {isViolated && (
      <span className="i-ps-warning_rou_s text-alert inline-block pr-1 align-bottom [--icon-size:18px]" />
    )}
  </LabelPrimitive.Root>
))
Label.displayName = LabelPrimitive.Root.displayName

const Description = React.forwardRef<
  HTMLParagraphElement,
  React.ComponentPropsWithoutRef<'p'>
>((props, ref) => (
  <p
    ref={ref}
    className={cn('text-label-l2 text-sm', props.className)}
    {...props}
  />
))

const FieldError = React.forwardRef<
  HTMLParagraphElement,
  React.ComponentPropsWithoutRef<'p'>
>((props, ref) => (
  <p
    ref={ref}
    className={cn('text-alert text-sm', props.className)}
    {...props}
  />
))

export { Description, FieldError, Label }
