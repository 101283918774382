import { EmailSchema, PasswordSchema } from 'utils/user-validation'
import { z } from 'zod'

export const AccountCheckSchema = z.object({
  email: EmailSchema,
})

export const AuthSchema = z.object({
  email: EmailSchema,
  password: PasswordSchema,
})
