import { cn } from '@paladise/ui/lib/utils'

interface Prop {
  className?: string
  text?: string
}

const DividerWithText = ({ text, className }: Prop) => {
  return (
    <div className={cn('text-label-l3 flex items-center', className)}>
      <hr className="flex-grow" />
      {text && <span className="px-2">{text}</span>}
      <hr className="flex-grow" />
    </div>
  )
}

export default DividerWithText
