import * as React from 'react'

import { cn } from '@paladise/ui/lib/utils'

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  isInvalid?: boolean
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, isInvalid, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          'border-background-2nd bg-background-2nd placeholder:text-label-l2 text-md flex min-h-[80px] w-full rounded-[12px] border px-3 py-2.5 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
          isInvalid && 'border-alert',
          className,
        )}
        ref={ref}
        {...props}
      />
    )
  },
)
Textarea.displayName = 'Textarea'

export { Textarea }
