import { z } from 'zod'

export const EmailSchema = z.string({ required_error: '*required' }).email({
  message: 'incorrect_email_format',
})

export const PasswordSchema = z
  .string({ required_error: '*required' })
  .min(6, { message: 'password_need_6_char' })

export const UsernameSchema = z.string({ required_error: 'Required' })
