import { checkUserEmail } from 'api/user'
import { clientFetch } from 'lib/fetch/client'

export const checkEmailAvailability = async (email: string) => {
  try {
    const response = await checkUserEmail(
      clientFetch,
      encodeURIComponent(email),
    )
    return response.data.available
  } catch (error) {
    console.error('error', error)
    return false
  }
}
