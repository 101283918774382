'use client'
import React, { useTransition } from 'react'
import { TextField } from '@paladise/ui/components/TextField'
import { Button } from '@paladise/ui/components/ui/button'
import { useTranslations } from 'next-intl'
import { AuthSchema } from '../schema'
import { useForm } from 'react-hook-form'
import { AuthSchemaType } from '../types'
import { zodResolver } from '@hookform/resolvers/zod'
import { handleSignUp } from '../actions/auth'
import useToast from 'utils/hooks/useToast'
import { checkEmailAvailability } from 'features/account/utils/checkEmailAvailability'

interface Prop {
  onLogin: () => void
}

function SignUp({ onLogin }: Prop) {
  const t = useTranslations()
  const [isLoading, startTransition] = useTransition()
  const { showErrorToast, showSuccessToast } = useToast()

  const {
    formState: { errors },
    register,
    handleSubmit,
    setError,
  } = useForm<AuthSchemaType>({
    resolver: zodResolver(AuthSchema),
    mode: 'onChange',
  })

  const fieldItem = [
    {
      name: 'email',
      label: 'Email',
      placeholder: 'Email',
      isInvalid: !!errors.email,
      errorMessage: errors.email?.message,
      disable: true,
    },
    {
      name: 'password',
      label: 'Password',
      placeholder: '6-30 characters',
      isInvalid: !!errors.password,
      errorMessage:
        errors.password?.message && t(errors.password?.message as 'required'),
    },
  ]

  const handleSubmitSignUp = async (data: AuthSchemaType) => {
    try {
      const available = await checkEmailAvailability(data.email)

      if (!available) {
        setError('email', {
          type: 'custom',
          message: 'Account already exists.',
        })
        return
      }
      const userId = await handleSignUp(data)
      if (!userId) throw new Error('SignU Failed')
      showSuccessToast({ description: 'SignUp Success' })
      window.location.hash = ''
    } catch (error) {
      showErrorToast({ description: 'SignUp Failed' })
      console.log(error)
    }
  }

  return (
    <div className="min768:w-[300px] mx-auto h-full w-full">
      <form
        className="text-callout text-label-l1 flex flex-col space-y-4 pb-4 pt-2"
        onSubmit={data => {
          startTransition(async () => {
            await handleSubmit(handleSubmitSignUp)(data)
          })
        }}
      >
        {fieldItem.map(item => (
          <TextField
            required
            {...register(item.name as 'email' | 'password', { required: true })}
            key={item.name}
            name={item.name}
            label={item.label}
            isInvalid={item.isInvalid}
            errorMessage={item.errorMessage}
            placeholder={item.placeholder}
            containerProps={{ className: 'space-y-[6px]' }}
            className="placeholder:text-label-l3"
          />
        ))}

        <Button type="submit" disabled={isLoading} loading={isLoading}>
          Sign Up
        </Button>
      </form>

      <p className="text-label-l2 text-caption">
        By clicking “Sign Up”, you agree to the User Terms of Service and
        Privacy Policy.
      </p>

      <p className="text-label-l1 text-caption">
        Already have an account?
        <Button
          variant="text"
          className="text-subtitle text-primary-p1 ml-1 p-0"
          onClick={onLogin}
        >
          Log In
        </Button>
      </p>
    </div>
  )
}

export default SignUp
