'use client'

import { Dialog } from '@paladise/ui/components/ui/dialog'
import { useDevice } from 'store/provider/device-provider'
import { HASH_KEY, useHash } from 'utils/hooks/useHash'
import QrcodeModal from './QrcodeModal'

const QrcodeWrapper = ({ children }: { children: React.ReactNode }) => {
  const hash = useHash()
  const device = useDevice()

  return (
    <Dialog
      open={hash === `#${HASH_KEY.DOWNLOAD_APP}` && device === 'desktop'}
      onOpenChange={open => {
        if (!open) {
          window.location.hash = ''
        }
      }}
    >
      <QrcodeModal />
      {children}
    </Dialog>
  )
}

export default QrcodeWrapper
