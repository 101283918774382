'use client'

import { TrackerProvider } from '@paladise/tracker/context'
import { useVisibilityTracker } from '@paladise/tracker/hooks/useVisibilityTracker'
import type { SessionTrackerBody } from '@paladise/tracker/types'
import { sessionTrackerInActive, sessionTrackerResumed } from 'api/tracker'
import { clientFetch } from 'lib/fetch/client'
import { env, session, v2Token } from 'store/server-context/static'
import { useWebsocket } from 'utils/hooks/useWebsocket'

const TrackerRegister = ({
  sessionTrackerBody,
  sessionId,
  children,
}: {
  sessionTrackerBody: SessionTrackerBody
  sessionId: string
  children: React.ReactNode
}) => {
  const [_, sendMessage] = useWebsocket(env.HOST_TRACKER, v2Token)

  // initialize page visibility tracker
  useVisibilityTracker(sessionTrackerBody, sessionId, {
    sessionTrackerInActive: body => sessionTrackerInActive(clientFetch, body),
    sessionTrackerResumed: body => sessionTrackerResumed(clientFetch, body),
  })

  // // initialize auto bind tracker
  // useAutoBindTracker()

  // // initialize observer tracker
  // useObserverTracker()

  // // initialize page name tracker
  // usePageNameTracker()

  return (
    <TrackerProvider
      value={{
        sendTrackerMessage: sendMessage,
        sessionId,
        userId: session.userId,
      }}
    >
      {children}
    </TrackerProvider>
  )
}

export default TrackerRegister
