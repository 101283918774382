'use client'
import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@paladise/ui/components/ui/dialog'
import SignUp from './SignUp'
import { AuthDialogEnum } from '../constants'
import Login from './Login'
import { HASH_KEY, useHash } from 'utils/hooks/useHash'
import { useDevice } from 'store/provider/device-provider'
import DividerWithText from 'components/DividerWithText'
import { Button } from '@paladise/ui/components/ui/button'
import ThirdPartyAuth from './ThirdPartyAuth'

function AuthDialog() {
  const hash = useHash()
  const device = useDevice()
  const [type, setType] = useState<AuthDialogEnum>()
  const isOpen = hash === `#${HASH_KEY.AUTH_LOGIN}` && device === 'desktop'
  useEffect(() => {
    if (isOpen) return
    setType(undefined) // Reset AuthDialog Type
  }, [isOpen])

  return (
    <Dialog
      open={isOpen}
      onOpenChange={open => {
        if (!open) {
          window.location.hash = ''
        }
      }}
    >
      <DialogContent
        className="flex h-[650px] w-[80%] max-w-[600px] flex-col justify-start pb-28"
        showClose
        aria-describedby={undefined}
      >
        <DialogTitle className="mb-10 flex h-[30px] justify-center">
          <img
            src="/images/logo.svg"
            alt="PalUp"
            role="img"
            aria-label="Logo"
            className="!h-[30px] !w-[74px]"
          />
        </DialogTitle>

        {!type && (
          <div className="min768:w-[300px] mx-auto h-full w-full">
            <h2 className="text-label-l1 text-title mb-2">Sign up for PalUp</h2>
            <p className="text-label-l2 text-body mb-8">
              Sign up to get free credits to chat with diverse AI pals and
              create your own.
            </p>

            <ThirdPartyAuth />
            <DividerWithText className="mb-8 mt-6" text="Or" />

            <Button
              onClick={() => {
                setType(AuthDialogEnum.SignUp)
              }}
              className="text-label-l1 border-label-l3 bg-background-2nd mb-4 h-12 w-full border text-[15px] font-bold"
            >
              Create a New Account
            </Button>

            <p className="text-caption text-label-l2">
              By signing up, you agree to the User Terms of Service and Privacy
              Policy.
            </p>

            <p className="text-label-l1 text-body my-4">
              Already have an account?
              <Button
                variant="text"
                className="text-subtitle text-primary-p1 ml-1 p-0"
                onClick={() => {
                  setType(AuthDialogEnum.Login)
                }}
              >
                Log In
              </Button>
            </p>
          </div>
        )}

        {type === AuthDialogEnum.SignUp && (
          <SignUp
            onLogin={() => {
              setType(AuthDialogEnum.Login)
            }}
          />
        )}

        {type === AuthDialogEnum.Login && (
          <Login
            onSignUp={() => {
              setType(AuthDialogEnum.SignUp)
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default AuthDialog
